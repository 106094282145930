import React, { useContext, useEffect, useMemo, useReducer, useState } from "react";
import { ContactContext, defaultState, EDIT_CONTACT_MODE, FETCH_DONE, FETCH_ERROR, reducer, SET_TAB } from "./models";
import { PageContainer } from "../components/PageContainer/PageContainer";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid/Grid";
import GlobalContext from "../context/global-context";
import { useLocation, useParams } from "react-router-dom";
import { ClaimApiClient, PolicyApiClient, PolicyholderApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients";
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import { FETCH_START } from "./models/action";
import { EContactType, IClaim, IPolicy, IPolicyholder, MessageBox, PolicyholderUtils, PolicyUtils } from "@surelync/common";
import Typography from "@material-ui/core/Typography";
import { BannerClientLogo } from "../components";
import Tabs from "../components/Tabs/Tabs";
import Box from "@material-ui/core/Box";
import TabIdentityAndAddress from "./components/TabIdentityAndAddress/TabIdentityAndAddress";
import TabOverview from "./components/TabOverview/TabOverview";
import TabPolicies from "./components/TabPolicies/TabPolicies";
import TabClaims from "./components/TabClaims/TabClaims";
import TabFees from "./components/TabFees/TabFees";
import { ETab } from "./models/tabs.model";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TabNotes from "./components/TabNotes/TabNotes";
import TabFiles from "./components/TabFiles/TabFiles";
import { setDataFees } from "./components/TabFees/helper";
import TabEnquiries from "./components/TabEnquiries/TabEnquiries";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";
import { useTheme } from "@material-ui/core";

const formatBadge = (data: Array<IPolicy | IClaim>) => (data && data.length ? ` (${data.length})` : "");

const ContactOverviewPage: React.FC = () => {
    const params = useParams<{ id: string }>();
    const [state, dispatch] = useReducer(reducer, defaultState);
    const { products, namespacedLocalStorage } = useContext(GlobalContext);
    const [fullName, setFullName] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { t } = useTranslation();
    const theme = useTheme();
    const location = useLocation<{ activeTab: ETab }>();

    const { activeTab, claims, enquiries, error, fetching, notes, policyholder, policies } = state;

    const names = useMemo(() => {
        return [
            t("overview"),
            t("identityAndAddress"),
            // "rfqs",            // hide for now
            `${t("policies")}${formatBadge(policies)}`,
            `${t("claims")}${formatBadge(claims)}`,
            `${t("fees")}${formatBadge(setDataFees(claims || [], policies || []))}`,
            `${t("notes")}${formatBadge(notes)}`,
            `${t("enquiries")}${formatBadge(enquiries)}`,
            `${t("files")}${formatBadge(
                PolicyUtils.getDocuments(
                    { items: policies || [], basepath: "" },
                    { items: claims || [], basepath: "" },
                    { items: Object.values(products || {}), basepath: "" },
                    "",
                    ""
                )
            )}`,
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policies, products, claims, notes, enquiries]);

    // fetch data request
    useEffect(() => {
        let isMount = true;
        const fetchData = async () => {
            const claimApiClient = new ClaimApiClient(namespacedLocalStorage);
            const policyholderApiClient = new PolicyholderApiClient(namespacedLocalStorage);
            const policyApiClient = new PolicyApiClient(namespacedLocalStorage);

            try {
                dispatch({ type: FETCH_START });
                const policyholder: IPolicyholder = await policyholderApiClient.getPolicyholder(params.id);
                const countryCode = policyholder.shared.schemaCountry;
                const universalPolicyholderSchema = await policyholderApiClient.getSchema(countryCode, policyholder.type);

                // const statuses = [EPolicyStatus.Quoted, EPolicyStatus.Bound, EPolicyStatus.Dtq, EPolicyStatus.Referral, EPolicyStatus.Cancelled];
                const responsePolicy = await policyApiClient.search({
                    policyholderIds: policyholder.id,
                    // statuses,
                    "load.product": true,
                    "load.attachments": true,
                    "load.sections": true,
                    "load.transactions": true,
                });
                const policies = responsePolicy.items;
                const responseClaims = await claimApiClient.search({ claimantIds: params.id });

                // cleanup func
                if (!isMount) return;

                dispatch({ type: FETCH_DONE, payload: { policyholder, policies, claims: responseClaims.items, universalPolicyholderSchema } });

                if (location.state && location.state.activeTab) {
                    dispatch({ type: SET_TAB, payload: location.state.activeTab });
                }
            } catch (error) {
                dispatch({ type: FETCH_ERROR, payload: generateErrorMessage(error) });
            }
        };

        fetchData();

        return () => {
            isMount = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id]);

    useEffect(() => {
        if (!policyholder) {
            return;
        }

        if (policyholder.type === EContactType.Individual) {
            setFullName(PolicyholderUtils._renderFullName(policyholder));
        } else {
            if (!policyholder.shared || !policyholder.shared.company) {
                return;
            }
            setFullName(policyholder.shared.company.name);
        }
    }, [policyholder]);

    const handleActions = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEditContact = () => {
        handleClose();
        dispatch({ type: SET_TAB, payload: ETab.IdentityAndAddress });
        dispatch({ type: EDIT_CONTACT_MODE, payload: true });
    };

    const renderTab = () => {
        switch (activeTab) {
            case ETab.Overview:
                return <TabOverview />;
            case ETab.IdentityAndAddress:
                return <TabIdentityAndAddress />;
            // case ETab.Rfqs:
            //   return <TabRfqs />;
            case ETab.Policies:
                return <TabPolicies />;
            case ETab.Claims:
                return <TabClaims />;
            case ETab.Fees:
                return <TabFees />;
            case ETab.Notes:
                return <TabNotes />;
            case ETab.Enquiries:
                return <TabEnquiries />;
            case ETab.Files:
                return <TabFiles />;
            default:
                return null;
        }
    };

    const renderTitle = policyholder ? `${fullName} - ${t("id")} ${params.id}` : t("contact");
    const lastUpdated = policyholder ? FormatUtils.renderDate(policyholder.ts) : "";

    return (
        <ContactContext.Provider value={[state, dispatch]}>
            <PageContainer fetching={fetching} title={renderTitle}>
                <Grid container alignItems="flex-end" spacing={2}>
                    <Grid item xs={6} sm={8} md={5}>
                        <Typography variant="h5">{fullName}</Typography>
                    </Grid>

                    <Grid item xs={6} sm={4} md={2}>
                        <Box textAlign={{ xs: "right", md: "center" }}>
                            <BannerClientLogo />
                        </Box>
                    </Grid>

                    <Hidden xsUp={!policyholder}>
                        <Grid item xs={12} sm={12} md={5}>
                            <Box textAlign="right">
                                <Typography variant="body2">
                                    {t("lastUpdated")}: {lastUpdated}
                                </Typography>
                            </Box>
                        </Grid>
                    </Hidden>

                    <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0, marginTop: -8 }}>
                        <Grid container alignItems="flex-end" spacing={0}>
                            <Grid item>
                                <Typography color="textPrimary" variant="body2">
                                    {`${t("id")} - ${params.id}`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container justify="space-between" spacing={2}>
                            <Grid item style={{ maxWidth: "100%" }}>
                                <Tabs names={names} value={activeTab} onChange={(value) => dispatch({ type: SET_TAB, payload: value })} />
                            </Grid>

                            <Hidden xsUp={activeTab !== ETab.Overview}>
                                <Grid item>
                                    <Grid container justify="flex-end" spacing={2} wrap="nowrap">
                                        <Grid item>
                                            <Button
                                                aria-controls="actions-menu"
                                                aria-haspopup="true"
                                                data-testid="actions"
                                                variant="text"
                                                onClick={handleActions}
                                            >
                                                {t("actions")} <ArrowDropDownIcon />
                                            </Button>
                                            <Menu
                                                id="actions-menu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                transformOrigin={{
                                                    vertical: "top",
                                                    horizontal: "left",
                                                }}
                                                onClose={handleClose}
                                            >
                                                <MenuItem data-testid="edit-contact" onClick={handleEditContact}>
                                                    {t("editContact")}
                                                </MenuItem>
                                                {/* <MenuItem onClick={handleDelete}>{t('deleteContact')}</MenuItem> */}
                                            </Menu>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        {error ? <MessageBox message={error} theme={theme} variant="error" onClose={() => dispatch({ type: FETCH_ERROR, payload: null })} /> : null}
                    </Grid>

                    <Grid item xs={12}>
                        {policyholder && renderTab()}
                    </Grid>
                </Grid>
            </PageContainer>
        </ContactContext.Provider>
    );
};

export default ContactOverviewPage;
