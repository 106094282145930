import React, { useContext, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { HomeContext } from "../../models/context";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box/Box";
import { Pagination } from "../Pagination/Pagination";
import { BannerClientLogo } from "../../../components";
import {
    CurrencyUtils,
    EPolicyStatus,
    ETypes,
    IPolicyholder,
    ISearchResponse,
    localeService,
    MessageBox,
    PolicyholderUtils,
    PolicyUtils,
    Preloader,
    RecordsNotFound
} from "@surelync/common";
import { useHistory, useLocation } from "react-router-dom";
import { PathByStatus } from "../../../@Policies/tabs.model";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";
import { Hidden, useTheme } from "@material-ui/core";
import { FETCH_ERROR } from "../../models";

type IResultsProps = Record<string, unknown>;

export const Results: React.FC<IResultsProps> = () => {
    const [state, dispatch] = useContext(HomeContext);
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const theme = useTheme();

    const { data, error, fetching, mobileSearchBtn, searchParams, pagination } = state;

    // [SUR-92] “Direct hits” of one result bypass search results listing
    // the “direct hit” search logic in React should be disabled for searches
    // that did not have either any text typed into text fields or anything entered into date fields
    useEffect(() => {
        const isSingleValue = Array.isArray(data) && data.length === 1 ? data[0] : (data as any); // SUR-178 contains("@") Policyholder API (not Search API) is used to find using email address
        const isFirstPage = pagination?.offset === 0;
        const isSearchParams = searchParams && !!Object.keys(searchParams).length;

        if (isSingleValue && isFirstPage && isSearchParams) {
            handleClick(isSingleValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleClick = (item: ISearchResponse) => {
        switch (item.type) {
            case ETypes.individual:
            case ETypes.policyholder:
                history.push({ pathname: `/contacts/${item.id}`, state: { from: location.pathname } });
                break;
            case ETypes.policy: {
                const { status } = item.tags;
                const path = (PathByStatus(status) || "policies").toLowerCase();
                const backBtnlabel = status === EPolicyStatus.Referral ? t("referrals") : t(path);
                const pathname = status === EPolicyStatus.Referral ? "referral" : "policies";

                const state = {
                    from: location.pathname,
                    backBtnlabel,
                    redirectTo: `/${path}`,
                };
                history.push({ pathname: `/${pathname}/${item.tags.id}`, state });
                break;
            }
            case ETypes.claim: {
                const state = { from: location.pathname, backBtnlabel: t("claims"), redirectTo: "/claims" };
                history.push({ pathname: `/claims/${item.id}`, state });
                break;
            }
            // case 'rfq': {
            //   const state = { from: location.pathname, backBtnlabel: t('policies'), redirectTo: '/policies' }
            //   history.push({pathname: `/referral/${item.tags.id}`, state});
            //   break;
            // }
            default:
                return null;
        }
    };

    const renderItem = (item: ISearchResponse) => {
        switch (item.type) {
            case ETypes.policyholder: {
                const {
                    city,
                    name, // represents company name
                    firstName,
                    firstNameFurigana,
                    lastName,
                    lastNameFurigana,
                    primaryEmailAddress,
                    schemaCountry,
                    title,
                    zone,
                    subzone
                } = item.tags;
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {name // if company name exists, then it is a company so use that
                                    ? name
                                    : PolicyholderUtils._renderFullName({
                                        shared: {
                                            personal: {
                                                dateOfBirth: "",
                                                title,
                                                firstName,
                                                lastName,
                                            },
                                            firstNameFurigana,
                                            lastNameFurigana,
                                            schemaCountry,
                                        },
                                    } as IPolicyholder)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} container spacing={2}>
                            <Grid item xs={6} md={5} lg={3} xl={2}>
                                <Typography variant="body1" title={primaryEmailAddress} noWrap>
                                    {primaryEmailAddress}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body1" title={city} noWrap>
                                    {subzone}
                                    {subzone && zone ? ", " : ""}
                                    {zone}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            }
            case ETypes.policy: {
                const { currency, id, productName, status } = item.tags;
                const inceptionDate = FormatUtils.renderDate(item.tags.inceptionDate);
                const fullTermValue = item.tags["totalPayable.fullTerm"];
                const fullTerm = CurrencyUtils._renderCurrency(Number(fullTermValue), localeService.locale, currency);
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h6">{productName}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" title={inceptionDate} noWrap>
                                {t("inception")} {t("was")} {inceptionDate}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} container spacing={2}>
                            <Grid item xs={6} md={5} lg={3} xl={2}>
                                <Typography variant="body1" noWrap>
                                    {t("status")} {t("is")} {PolicyUtils.getStatus(status as EPolicyStatus)}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                {fullTerm ? (
                                    <Typography variant="body1" title={`${fullTerm} ${t("premium")}`} noWrap>
                                        {fullTerm} {t("premium")}
                                    </Typography>
                                ) : null}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2">{id}</Typography>
                        </Grid>
                    </Grid>
                );
            }
            // case 'rfq': {
            //   const { productName, status } = item.tags;
            //   const inceptionDate = FormatUtils.renderDate(item.tags.inceptionDate);
            //   return (
            //     <Grid container>
            //       <Grid item xs={12}>
            //         <Typography variant="h6">
            //           {t('rfq')} {productName ? productName : ''}
            //         </Typography>
            //       </Grid>
            //       <Grid item xs={12}>
            //         <Typography variant="body1" noWrap>
            //           {t('dateCreated')} {inceptionDate}
            //         </Typography>
            //       </Grid>
            //       <Grid item xs={12}>
            //         <Typography variant="body1" noWrap>
            //           {t('status')} {t('is')} {t(status.toLowerCase())}
            //         </Typography>
            //       </Grid>
            //     </Grid>
            //   );
            // }
            case ETypes.claim: {
                const { category, currency, productName } = item.tags;
                const creationDate = FormatUtils.renderDate(item.tags.creationDate);
                const dateOfIncident = FormatUtils.renderDate(item.tags.dateOfIncident);
                const claimAmount = CurrencyUtils._renderCurrency(Number(item.tags.claimAmount), localeService.locale, currency);

                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {t("claim")} {t("on")} {productName}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} container spacing={2}>
                            <Grid item xs={6} md={5} lg={3} xl={2}>
                                <Typography variant="body1" title={`${t("created")} ${t("on")} ${creationDate}`} noWrap>
                                    {t("created")} {t("on")} {creationDate}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body1" title={`${t("dateOfIncident")} ${dateOfIncident}`} noWrap>
                                    {t("dateOfIncident")} {dateOfIncident}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container spacing={2}>
                            <Grid item xs={6} md={5} lg={3} xl={2}>
                                <Typography variant="body1" title={`${claimAmount} ${t("claimAmount")}`} noWrap>
                                    {claimAmount} {t("claimAmount")}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body1" title={category} noWrap>
                                    {t("category")} {t("is")} {category}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2">{item.id}</Typography>
                        </Grid>
                    </Grid>
                );
            }
            default:
                return <Typography variant="h6">{t(item.type)}</Typography>;
        }
    };

    return (
        <Box px={3} pb={3}>
            <Grid container direction="column" spacing={2} data-testid="search-result">
                <Grid item xs={12}>
                    <Grid container wrap="wrap-reverse">
                        <Grid item>
                            <Box pt={3}>
                                {data && searchParams?.search ? (
                                    <Typography variant="h6" data-testid="search-result-header">
                                        {t("searchResultsForCustomer", { name: searchParams?.search })}
                                    </Typography>
                                ) : null}

                                {pagination && pagination.total ? <Pagination /> : null}
                            </Box>
                        </Grid>
                        <Hidden smDown={true} mdDown={mobileSearchBtn}>
                            <Grid item xs>
                                <Box textAlign="right" pt={1}>
                                    <BannerClientLogo />
                                </Box>
                            </Grid>
                        </Hidden>
                    </Grid>
                </Grid>

                {error ? (
                    <Grid item xs={12} lg={10} xl={6}>
                        <MessageBox message={error} theme={theme} variant="error" onClose={() => dispatch({ type: FETCH_ERROR, payload: null })} />
                    </Grid>
                ) : null}

                {fetching && <Preloader fullHeight theme={theme} />}

                {data && data.length ? (
                    <Grid item>
                        <List data-testid="list-content">
                            {data.map((item: ISearchResponse, index: number) => (
                                <ListItem divider key={index} onClick={() => handleClick(item)} button data-testid="result-item">
                                    {renderItem(item)}
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                ) : null}

                {data && !data.length ? (
                    <Grid container justify="center" alignItems="center" style={{ height: "70vh" }}>
                        <Grid item>
                            <RecordsNotFound theme={theme} />
                        </Grid>
                    </Grid>
                ) : null}

                {!fetching && pagination && pagination.total && (pagination.limit > 10 || window.innerWidth < 1024) ? (
                    <Grid item>
                        <Pagination hideSort={true} />
                    </Grid>
                ) : null}
            </Grid>
        </Box>
    );
};
