import React, {useState} from 'react';
import '../../../styles/AutoSuggest.css';
import {useDefaultTranslation} from '../../../common/Translation';
import { changeRequiredMessage, resetRequiredMessage } from '../../SchemaUtils';
import {getCorrectCountry, getCountry} from './helper';
import countries from '@blocksure/blocksure-core/dist/src/utilities/countries.json'
import {StartingPrefix} from "@blocksure/blocksure-core/src/components/PhoneNumberUtils";

const validationFunctions = {
    default: value => value === '' || value.match(/^(\d*\s*)+$/)
};

const PhoneNumberField = ({
    schema,
    uiSchema,
    formData,
    onChange,
    name,
    required,
    disabled
}) => {
    const {t} = useDefaultTranslation();
    let [phoneNumber, setPhoneNumber] = useState();
    let [prefix, setPrefix] = useState();
    const placeholder = uiSchema['ui:placeholder'] || t('enterPhone');
    const country = getCorrectCountry(getCountry(uiSchema));
    const validate = validationFunctions[country] || validationFunctions.default;
    if (!validate) throw Error(`Unknown country [${country}].`);
    const countriesOption = [...new Set(countries.map(c => c.phonePrefix).sort())];

    prefix = StartingPrefix(formData, country);

    if(formData !== undefined && phoneNumber === undefined) {
        phoneNumber = formData;
        if(phoneNumber.startsWith(prefix.replace('+', ''))) {
            phoneNumber = phoneNumber.replace(prefix.replace('+', ''), '');
        }

        if(phoneNumber !== '') {
            phoneNumber = phoneNumber?.replace(prefix + " ", "");
        }

        if(phoneNumber !== undefined && !phoneNumber.startsWith('0')) {
            phoneNumber = `0${phoneNumber}`;
        }
    }

    const onPhoneChange = (e) => {
        resetRequiredMessage(e);
        setPhoneNumber(e.target.value);
        validate(e.target.value) && onChange(prefix + " " + e.target.value);
    };

    const onChangePrefix = (e) => {
        setPrefix(e.target.value);
        onChange(e.target.value + " " + (phoneNumber || ''));
    };

    return (
            <div>
                <label className="col-form-label" htmlFor="root_contactPreference">{schema.title || name}{required ? '*' : ''}</label>
                <div style={{display: "flex"}}>
                <select
                    style={{width: "100px"}}
                    id="phone_number_prefix"
                    className="form-control"
                    value={prefix}
                    onChange={onChangePrefix}>
                    {countriesOption.map((it) => (<option key={it} value={it}>{it}</option>))}
                </select>
                <input disabled={disabled} minLength={7} maxLength={20} className="form-control" placeholder={placeholder} required={required} value={phoneNumber === undefined ? '' : phoneNumber}
                       onChange={onPhoneChange} onInvalid={required ? (event => changeRequiredMessage(event, t)) : null}/>
                </div>
            </div>
    );
};

export default PhoneNumberField;
